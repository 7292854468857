body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p{
  margin: 0 !important;

}
h5, h3{
  color: #F3F4F6 !important;
  text-align: center;
}
table{
  margin: 10px auto;
  max-width: 95%;
  min-width: 95%;
  

}
table p {
  font-size: 10px !important;
}
.table-striped{
  background: #F3F4F6;
}
#table{
  box-shadow: 0px 0px 2px black !important;
  border-radius:  5px;
  background: #F3F4F6;
}
.btn{
  margin: 5px;
}
.sidebar_role{
  padding-top: 8px;
  font-size: 17px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
}
.active_nav{
  color: #f3f4f6 !important;
  background-color: rgba(255, 255, 255, 0.1);
  display: inline-block;
}
.dev_title{
  font-size: 30px;
  font-weight: bold;
}
.btn_div{
  text-align: center;
}
select, button{
  outline: none;
}
button{
  border: none;
}
td, th{
  padding: 1px !important;
}