@import url("https://fonts.googleapis.com/css2?family=Potta+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Potta+One&display=swap");
._404 {
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #03a9f4, #03a9f4 50%, #333 50%, #333);
}
.box {
  position: absolute;
  text-align: center;
}
.box h3 {
  font-size: 28px;
  color: #fff;
  margin-bottom: 20px;
}
.box a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  transition: all 0.3s;
  padding: 10px;
  border: 1.5px solid #fff;
  border-radius: 10px;
}
.box a:hover {
  border: 1.5px solid #03a9f4;
  color: #03a9f4;
}
.box h2 {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15rem;
  text-shadow: -25px 25px 40px rgba(0, 0, 0, 0.5),
    -10px 10px 0 rgba(255, 255, 255, 0.5), -20px 20px 0 rgba(255, 255, 255, 0.2),
    -30px 30px 0 rgba(255, 255, 255, 0.05);
}
.box h2 .zero {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 200px;
  background: linear-gradient(to bottom, #fff, #777);
  border-radius: 50%;
  margin: 0 15px;
  box-shadow: -25px 25px 40px rgba(0, 0, 0, 0.5);
}
.box h2 .zero::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 180px;
  height: 180px;
  background: linear-gradient(to top, #fff, #777);
  border-radius: 50%;
}
.box h2 .zero::after {
  content: "";
  position: absolute;
  top: 2%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: linear-gradient(315deg, #03a9f4, #03a9f4 50%, #333 50%, #333);
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.5);
  animation: animate 5s infinite;
}
@keyframes animate {
  0% {
    transform: translate(-50%, 50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, 50%) rotate(360deg);
  }
}

@media only screen and (max-width: 480px) {
  .box h2 {
    font-size: 9rem;
  }
  .box h2 .zero {
    width: 130px;
    height: 130px;
  }
  .box h2 .zero::before {
    width: 110px;
    height: 110px;
  }
  .box h2 .zero::after {
    width: 60px;
    height: 60px;
  }
}
